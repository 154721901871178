<template>
  <div class="text-center">
    <h2 class="title title--form text-center">Подтвердите вашу почту</h2>
    <p v-if="isNew">Мы отправили вам письмо со ссылкой подтверждения на&nbsp;указанный вами e&#8209;mail:</p>
    <p v-else>Мы заметили, что вы не подтвердили <br>ваш e&#8209;mail:</p>
    <div class="form-email">{{email}}</div>
    <p>Перейдите по ссылке из письма, <br>чтобы завершить регистрацию</p>
    <template v-if="isNew">
      <div class="form-stepFinal">
        <p>
          <button
              type="button"
              class="link-simple"
              @click="repeatSending(email)"
              :disabled="counting"
          >
            Отправить письмо повторно
            <vue-countdown
                v-if="counting"
                :time="120000"
                @end="counting = false"
                v-slot="{ minutes, seconds }"
            >
              ({{ String(minutes).padStart(2, '0') }}:{{ String(seconds).padStart(2, '0') }})
            </vue-countdown>
          </button>
        </p>
      </div>
      <div class="form-stepFinal">
        <button
            type="button"
            class="button button--transparent"
            @click="resetForm"
        >Назад</button>
      </div>
    </template>
    <template v-else>
      <div class="form-stepFinal">
        <button
            type="button"
            class="button"
            :disabled="counting"
            @click="repeatSending(email)"
        >
          Отправить письмо повторно
          <vue-countdown
              v-if="counting"
              :time="120000"
              @end="counting = false"
              v-slot="{ minutes, seconds }"
          >
            ({{ String(minutes).padStart(2, '0') }}:{{ String(seconds).padStart(2, '0') }})
          </vue-countdown>
        </button>
      </div>
      <div class="form-stepFinal">
        <p>
          <nuxt-link class="link-simple" to="/registration">Регистрация</nuxt-link>
        </p>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
const emit = defineEmits(['resetForm']);
const props = defineProps({
  email: String,
  isNew: {
    type: Boolean,
    default: true,
  }
});

let errors = ref({
  message: null!,
  errors: {

  }
});

let counting = ref(false);
import VueCountdown from "@chenfengyuan/vue-countdown";
import {ApiRequest} from "~/utils/api-request";

const repeatSending = async (mail) => {
  counting.value = true;

  let confirmationData = {
    email: mail,
  };

  let apiRequest = new ApiRequest('post', 'user/email-confirm/resend', {}, confirmationData);

  apiRequest.onSuccess((data) => {
    // console.log(data);
  });

  apiRequest.onError((error) => {
    errors.value = error;
  });

  let data = await apiRequest.request();
  // console.log(data)
}
const resetForm = () => {
  emit('resetForm');
}
</script>

<style scoped>
.form-email{
  font-size: 18px;
  line-height: 1.5;
  margin: 24px auto 32px;
}
.form-stepFinal{
  margin-top: 32px;
}
</style>
